import React from 'react';
import Main from '../components/pages/Main';
import SiteMap from '../components/pages/SiteMap';

const MapaDoSite = () => (
  <Main page="mapa-do-site">
    <SiteMap />
  </Main>
);

export default MapaDoSite;
