import React from 'react';
import { Typography, Box, Link, makeStyles } from '@material-ui/core';
import ContentWrap from '../atoms/ContentWrap';
import GlazedEmoji from '../atoms/GlazedEmoji';
import ContentSpacer from '../molecules/ContentSpacer';
import SiteMapJson from '../../static/files/SiteMap.json';
import EmojiPin from '../../static/images/emoji-pin.png';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '190px 0 150px',

    '& .MuiTypography-root': {
      paddingBottom: '15px',
    },
    '& .MuiTypography-h3': {
      paddingTop: '15px',
    },
    '& .MuiLink-root': {
      color: theme.palette.midGrey.main,
    },

    '@media screen and (max-width: 959px)': {
      padding: '115px 0 50px',
    },
  },
}));

const SiteMap = () => {
  const classes = useStyles();

  return (
    <>
      <ContentWrap>
        <Box className={classes.root}>
          <GlazedEmoji img={EmojiPin} alt="Emoji de alfinete" />

          <Typography variant="h3" component="h1">Mapa do Site</Typography>

          <ul>
            {Object.entries(SiteMapJson).map(([pageName, url]) => (
              <Typography variant="h6" component="li">
                <Link href={url}>{pageName}</Link>
              </Typography>
            ))}
          </ul>
        </Box>
      </ContentWrap>
      <ContentSpacer />
    </>
  );
};

export default SiteMap;
